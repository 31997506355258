* {
  font-family: "Menlo", monospace;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
  overflow-x: hidden !important;
  background: linear-gradient(-95deg, #000000, #0d0d2b 60%, #000814);
}

footer,
header .contacto {
  padding: 20px;
  z-index: 10;
}

section {
  padding: 70px 0;
}

footer a {
  transition: transform 0.3s ease, color 0.3s ease;
}

footer a i {
  color: white;
}

footer a:hover i {
  transform: scale(1.2);
  color: #007bff;
}

.iiz__btn.iiz__hint {
  display: none !important;
}

.btn-close {
  filter: invert(100%);
  opacity: 1;
}

.course-img {
  transition: transform 0.2s ease;
  border: 1px solid white;
}

.course-img:hover {
  transform: scale(1.1);
}

.timeline-section {
  position: relative;
  width: 100%;
  height: 100%;
}

.timeline-date {
  text-align: left;
  color: white;
  position: relative;
  margin: 0;
  padding: 0;
  top: 0;
  left: -100px;
}

.animated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
}

.background-icon {
  position: absolute;
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.6);
  animation: fallIcons 15s ease-in-out infinite;
  z-index: 1;
}

.vertical-timeline-element-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  border-radius: 10px;
}

.vertical-timeline-element-content h3,
.vertical-timeline-element-content h4,
.vertical-timeline-element-content h5,
.vertical-timeline-element-content p {
  margin: 0;
  padding: 5px 0;
}

.vertical-timeline-element-content img {
  margin-top: 15px;
  border-radius: 10px;
  max-width: 100%;
  object-fit: contain;
}

.d-flex.align-items-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

button.minimal-button {
  background: none;
  border: none;
  color: #1e88e5;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

button.minimal-button:hover {
  color: #0056b3;
}

@keyframes fallIcons {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    transform: translateY(110%);
    opacity: 0;
  }
}

@keyframes fadeIcons {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(7px);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1001;
  pointer-events: none;
}

.backdrop.active {
  display: block;
  opacity: 1;
}

.hamburger-menu {
  position: fixed;
  top: 10px;
  right: -320px;
  width: 320px;
  height: calc(100vh - 20px);
  background: #111111;
  color: #f4f4f4;
  padding: 20px;
  transition: right 0.2s ease-in-out;
  z-index: 1002;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border-bottom: 2px solid #333;
  border-top: 2px solid #333;
  border-left: 2px solid #333;
}

.hamburger-menu.open {
  right: 0;
}

.menu-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  border: 2px solid #fff;
}

.user-info h4 {
  margin: 0;
  font-size: 1.1rem;
  color: #f1f1f1;
}

.user-info p {
  margin: 0;
  font-size: 0.7rem;
  color: #aaa;
}

.close-icon {
  font-size: 28px;
  color: #fff;
  cursor: pointer;
  margin-left: auto;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid transparent;
  transition: all 0.3s ease;
}

.close-icon:hover {
  border-color: #007bff;

}

.menu-navigation h5 {
  font-size: 1.1rem;
  color: #f1f1f1;
  margin-bottom: 15px;
  border-bottom: 1px solid #555;
  padding-bottom: 5px;
}

.menu-navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-navigation li {
  margin: 15px 0;
}

.menu-navigation a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.2s ease;
}

.menu-navigation a:hover {
  text-decoration: underline;
}

button.hover-underline,
a.hover-underline {
  color: white;
  background: none;
  border: none;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
}

button.hover-underline:hover,
a.hover-underline:hover {
  text-decoration: underline !important;
  transition: text-decoration 0.2s ease-in-out;
}

.social-icons {
  display: flex;
  justify-content: start;
  margin: 20px 0;
}

.social-link {
  margin: 0 15px;
  font-size: 1.8rem;
  color: #fff;
  transition: transform 0.2s ease, color 0.2s ease;
}

.social-link:hover {
  transform: scale(1.2);
  color: #007bff;
}

.download-buttons {
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.copyright {
  text-align: center;
  font-size: 0.8rem;
  color: #aaa;
  margin-top: 20px;
}

.header-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1 !important;
  animation: background-glow 1s ease-in-out infinite alternate;
  pointer-events: none;
  overflow: hidden;
}

.header-star,
.header-particle,
.shooting-star,
.satellite,
.spaceship {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.header-star {
  position: absolute;
  opacity: 0;
  animation: header-star-twinkle 3s infinite ease-in-out;
}

.header-particle {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  animation: header-particle-float 5s infinite linear;
}

.shooting-star {
  position: absolute;
  width: 0.5px;
  height: 80px;
  background: linear-gradient(90deg, rgba(255, 223, 0, 1), rgba(255, 223, 0, 0));
  animation: shooting-star-animation 3s ease-in-out infinite;
  filter: blur(2px);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 223, 0, 0.8);
  opacity: 0.8;
}

.satellite {
  position: absolute;
  font-size: 14px;
  animation: satellite-move 40s ease-in-out infinite;
  transform-origin: 50% 50%;
}

.spaceship {
  position: absolute;
  font-size: 20px;
  color: #ff4500;
  animation: spaceship-fly 25s linear infinite, spaceship-glow 3s infinite alternate;
}

.planet,
.moon {
  position: absolute;
  border-radius: 50%;
  transform-style: preserve-3d;
}

.planet {
  width: 35px;
  height: 35px;
  top: 40% !important;
  left: 70% !important;
  background: radial-gradient(circle at 30% 30%, #2d89ef, #00509e);
  box-shadow: 0 0 20px rgba(50, 150, 255, 0.6),
    0 0 50px rgba(50, 150, 255, 0.4),
    0 0 100px rgba(50, 150, 255, 0.2);
  animation: planet-glow 5s infinite alternate ease-in-out, planet-shine 3s infinite alternate;
}

.moon {
  width: 20px;
  height: 20px;
  top: 25% !important;
  left: 80% !important;
  background: radial-gradient(circle at 30% 30%, #d9d9d9, #4d4d4d);
  box-shadow: 0 0 10px rgba(200, 200, 200, 0.6),
    0 0 30px rgba(200, 200, 200, 0.4),
    0 0 60px rgba(200, 200, 200, 0.2);
  animation: moon-glow 5s infinite alternate ease-in-out;
}

@keyframes background-glow {
  0% {
    filter: brightness(1);
  }

  100% {
    filter: brightness(1.1);
  }
}

@keyframes planet-glow {
  0% {
    box-shadow: 0 0 20px rgba(50, 150, 255, 0.6),
      0 0 50px rgba(50, 150, 255, 0.4),
      0 0 100px rgba(50, 150, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 30px rgba(50, 150, 255, 0.8),
      0 0 70px rgba(50, 150, 255, 0.6),
      0 0 120px rgba(50, 150, 255, 0.4);
  }
}

@keyframes planet-shine {
  0% {
    filter: brightness(1);
    box-shadow: 0 0 20px rgba(50, 150, 255, 0.6),
      0 0 50px rgba(50, 150, 255, 0.4),
      0 0 100px rgba(50, 150, 255, 0.2);
  }

  100% {
    filter: brightness(1.3);
    box-shadow: 0 0 30px rgba(50, 150, 255, 0.8),
      0 0 70px rgba(50, 150, 255, 0.6),
      0 0 120px rgba(50, 150, 255, 0.4);
  }
}

@keyframes moon-glow {
  0% {
    box-shadow: 0 0 10px rgba(200, 200, 200, 0.6),
      0 0 30px rgba(200, 200, 200, 0.4),
      0 0 60px rgba(200, 200, 200, 0.2);
  }

  100% {
    box-shadow: 0 0 15px rgba(200, 200, 200, 0.8),
      0 0 40px rgba(200, 200, 200, 0.6),
      0 0 80px rgba(200, 200, 200, 0.4);
  }
}

@keyframes spaceship-glow {
  0% {
    text-shadow: 0 0 10px rgba(255, 69, 0, 0.8);
  }

  100% {
    text-shadow: 0 0 20px rgba(255, 165, 0, 1);
  }
}

@keyframes spaceship-fly {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  50% {
    transform: translate(300px, -200px) rotate(45deg);
  }

  100% {
    transform: translate(600px, 0) rotate(90deg);
  }
}

@keyframes header-star-twinkle {

  0%,
  100% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }
}

@keyframes header-particle-float {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }

  50% {
    transform: translateY(-50px);
    opacity: 0.6;
  }

  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes shooting-star-animation {
  0% {
    transform: translate(0, 0) rotate(45deg);
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    transform: translate(400px, -400px) rotate(45deg);
    opacity: 0;
  }
}

@keyframes planet-glow {
  0% {
    filter: brightness(1);
  }

  100% {
    filter: brightness(1.2);
  }
}

@keyframes moon-fade {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes satellite-move {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  25% {
    transform: translate(50px, 50px) rotate(90deg);
  }

  50% {
    transform: translate(100px, 0) rotate(180deg);
  }

  75% {
    transform: translate(50px, -50px) rotate(270deg);
  }

  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 30px !important;
  height: 30px !important;
  background-color: rgba(0, 0, 0, 1) !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  z-index: 10;
}

.carousel-control-prev {
  left: 5px !important;
}

.carousel-control-next {
  right: 5px !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  font-size: 18px !important;
  transform: scale(0.4) !important;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
  color: #1E90FF !important;
}

.tooltip-custom {
  position: absolute;
  z-index: 9999 !important;
  white-space: pre;
}

.card {
  position: relative;
  overflow: visible;
}

.row {
  position: relative;
  overflow: visible;
}

.carousel-indicators [data-bs-target] {
  background-color: rgba(100, 100, 100, 0.7) !important;
  mix-blend-mode: difference !important;
  width: 10px;
  height: 10px;
}

.carousel-indicators .active {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.custom-pagination .page-item .page-link {
  background-color: transparent !important;
  border: none !important;
  color: #f4f4f4 !important;
  transition: all 0.3s ease;
  cursor: pointer;
}

.custom-pagination .page-item.active .page-link {
  background-color: transparent !important;
  border-radius: 8px;
  font-weight: bold;
}

.custom-pagination .page-item .page-link .custom-page-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.custom-page-item {
  background-color: transparent !important;
  border: none !important;
  color: #f4f4f4 !important;
  cursor: pointer;
}

.custom-page-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 20%;
}

.hover-gold:hover {
  color: gold !important;
}